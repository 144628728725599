import { inject, ref, computed } from 'vue'
import { useCountryStore } from './country'

export const useSettingsStore = defineStore('settings-store', () => {
  const countryStore = useCountryStore()
  const fetchApi = inject('fetchApi') as Function
  const windowWidth = ref<number>(window.innerWidth)
  const isMobile = computed(() => windowWidth.value < 767)
  const isTablet = computed(() => windowWidth.value < 992)
  const jobCategories = ref({})
  const jobSkills = ref({})

  async function getJobCategories() {
    try {
      const res = await fetchApi('/api/v3/job-categories', countryStore.backend)
      const response = await res.json()
      jobCategories.value.data = response.data.map((item) => {
        const modifiedItem = item
        modifiedItem.icon_path = item.icon_path.replace('white-line', 'no-fill')
        return modifiedItem
      })
    } catch (error) {
      throw new Error(`getJobCategories error: ${error}`)
    }
  }

  async function getJobSkills() {
    try {
      const res = await fetchApi('/api/v3/skills', countryStore.backend)
      jobSkills.value = await res.json()
    } catch (error) {
      throw new Error(`getJobSkills error: ${error}`)
    }
  }

  function setWidth(number:number) {
    windowWidth.value = number
  }
  return { windowWidth, isMobile, isTablet, setWidth, getJobCategories, getJobSkills, jobCategories, jobSkills }
})
